<template>
    <Setup-Step
        @yes="f => addNewFee()"
        @no="next"
        :customizeTo="stage == 0 ? { name: 'delivery-fees' } : null"
        :hideYesNo="false"
        :loadingMsg="loadingMsg"
        :settings="settings"
        :stage.sync="stage"
        step="delivery-fees"
        title="Delivery Fees">
        <template v-slot:body>
            <v-tabs-items v-model="stage" touchless>
                <v-tab-item>
                    <BT-List-Endless
                        :canSearch="false"
                        :getParams="() => { return { optionGroupID: 'Delivery Fee' }; }"
                        :loadingMsg.sync="loadingMsg"
                        maxHeight="60vh"
                        navigation="delivery-fees"
                        :refreshToggle="refreshList">
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.lineItemName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.lineValue | toCurrency }} for each order less than {{ item.quantity | toCurrency }} | {{ item.taxType == 'GST' ? 'Charge GST' : 'No GST' }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn small text @click="addNewFee(item)">change</v-btn>
                            </v-list-item-action>
                        </template>
                    </BT-List-Endless>
                    <p class="my-4">Would you like to add a delivery fee?</p>
                </v-tab-item>

                <v-tab-item>
                    <BT-Field-String
                        label="Fee Name"
                        v-model="newFee.lineItemName"
                        isEditing />
                    <BT-Btn 
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="stage += 1" />
                </v-tab-item>

                <v-tab-item>
                    <p>What is the price of this delivery fee?</p>
                    <BT-Field-Number
                        label="Delivery Fee Value"
                        v-model.number="newFee.lineValue"
                        isEditing
                        prefix="$"
                        textFilter="toCurrency" />
                    <BT-Btn
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="stage += 1" />
                </v-tab-item>

                <v-tab-item>
                    <p>Would you like to charge GST on top of this price?</p>
                    <BT-Btn
                        buttonClass="primary mx-1"
                        inline
                        label="No"
                        @click="noGST" />
                    <BT-Btn
                        buttonClass="primary mx-1"
                        inline
                        label="Yes"
                        @click="hasGST" />
                </v-tab-item>

                <v-tab-item>
                    <p>Charge this delivery fee only when orders are below this given value:</p>
                    <BT-Field-Number
                        label="Order Value"
                        v-model.number="newFee.quantity"
                        isEditing
                        prefix="$"
                        textFilter="toCurrency" />
                    <BT-Btn 
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="stage += 1" />
                </v-tab-item>

                <v-tab-item>
                    <p>Select the area that this fee will cover</p>
                    <GmapMap
                        :center="center"
                        :zoom="7"
                        style="width: 100%; min-height: 300px; height: 430px;"
                        :options="{
                            zoomControl: true,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: true,
                            disableDefaultUi: false }">

                            <gmap-polygon 
                                :paths="newFee.boundary"
                                draggable
                                @paths_changed="($event) => { updatePointerPaths($event, item) }"
                                editable
                                :fillOpacity="0.5"
                                class="polygon" />
                    </GmapMap>
                    <BT-Btn 
                        buttonClass="primary ma-1"
                        inline
                        label="Save"
                        @click="saveFee" />
                </v-tab-item>
            </v-tabs-items>
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Delivery-Fee-Setup',
    components: {
        SetupStep: () => import('~home/setup/Setup-Step.vue')
    },
    data() {
        return {
            center: { lat: -38, lng: 144 },
            loadingMsg: null,
            newFee: {},
            refreshList: false,
            stage: 0
        }
    },
    async mounted() {
        var locations = await this.$BlitzIt.store.getAll('locations');
        if (this.isLengthyArray(locations)) {
            this.center = locations[0];
        }
    },
    props: {
        settings: { //{ currentIndex: 0, stepperPlan: [], currentStep: 0 }
            type: Object,
            default: null
        },
    },
    methods: {
        addNewFee(fee) {

            this.newFee = fee || {
                sortNumber: 100,
                rule: 'ProductValueLessThan',
                quantity: 1,
                optionGroupID: 'Delivery Fee',
                lineValueType: 'FixedValue',
                lineValue: 0,
                linesWithTags: null,
                lineItemName: null,
                itemID: null,
                isOn: true,
                isReplacement: false,
                isLastMinute: false,
                description: 'Delivery Fee',
                calcOrder: 15,
                boundary: this.getAreaAround(this.center, 2),
                tags: null,
                taxType: 'GST'
            }

            this.stage += 1;
        },
        hasGST() {
            this.newFee.taxType = 'GST';
            this.stage += 1;
        },
        noGST() {
            this.newFee.taxType = null;
            this.stage += 1;
        },
        next(nextFunc) {
            nextFunc();
        },
        async saveFee() {
            this.loadingMsg = 'Saving';
            if (this.newFee.id == null) {
                await this.$BlitzIt.store.post('delivery-fees', this.newFee);
            }
            else {
                await this.$BlitzIt.store.patch('delivery-fees', this.newFee);
            }
            
            this.stage = 0;
            this.refreshList = !this.refreshList;
            this.loadingMsg = null;
        },
        updatePointerPaths(mvcArray) {
            let paths = [];
            for (let i = 0; i < mvcArray.getLength(); i++) {
              let path = [];
              for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
                let point = mvcArray.getAt(i).getAt(j);
                path.push({ lat: point.lat(), lng: point.lng() });
              }
              paths.push(path);
            }
            this.newFee.boundary = paths[0];
        }
    }
}
</script>